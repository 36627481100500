import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import SkillCard from './SkillsCard';

export const Skills = () => {
  const skills = [
    {
      title: "Programming Languages",
      skills: ["Python", "C", "Cpp", "Dart", "Java"]
    },
    {
      title: "Machine Learning",
      skills: ["SciPy", "Pandas", "NumPy", "Matplotlib", "PyTorch", "TensorFlow"]
    },
    {
      title: "Cloud",
      skills: ["AWS", "GCP"]
    },
    {
      title: "Database Management",
      skills: ["MySQL", "MongoDB", "S3"]
    },
    {
      title: "App Development",
      skills: ["Flutter", "Kotlin"]
    },
    {
      title: "Web Development",
      skills: ["HTML", "CSS", "JavaScript"]
    },
    {
      title: "Backend",
      skills: ["FastAPI", "NodeJs", "Firebase"]
    },
    {
      title: "Miscellaneous",
      skills: ["Git", "GitHub", "Figma", "MATLAB"]
    }
  ];

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };
  const CustomDot = ({ onClick, ...rest }) => {
    const active = rest.active;
    return (
      <li>
        <button
          style={{
            backgroundColor: active ? '#AA336A' : '#aaa',
            borderRadius: '50%',
            width: '10px',
            height: '10px',
            border: 'none',
            margin: '5px',
            cursor: 'pointer'
          }}
          onClick={() => onClick()}
        />
      </li>
    );
  };


  return (
    <section className="project" id="skills">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="skill-bx wow zoomIn">
              <h2>Skills</h2>
              <br />
              <Carousel
                responsive={responsive}
                autoPlay={true}
                swipeable={true}
                draggable={true}
                showDots={true}
                infinite={true}
                autoPlaySpeed={1500}
                arrows={false}
                partialVisible={true}
                customDot={<CustomDot />}
              >
                {skills.map((skillCategory, index) => (
                  <SkillCard key={index} title={skillCategory.title} skills={skillCategory.skills} />
                ))}
              </Carousel>

            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
