import React from 'react';
import { Card, Col } from 'react-bootstrap';

export const EducationCard = ({ title, degree, year, imageUrl }) => {
  return (
    <Col className="mb-4">
      <Card
        style={{
          backgroundColor: '#151515',
          color: 'white',
          border: 'none',
          borderRadius: 25,
          overflow: 'hidden',
          textAlign: 'center',
        }}
      >
        <Card.Img
          variant="top"
          src={imageUrl}
          style={{
            borderTopLeftRadius: 25,
            borderTopRightRadius: 25,
            height: '100%',
            objectFit: 'cover',
          }}
        />
        <Card.Body>
          <Card.Title
            style={{
              fontSize: '1.5rem',
              transition: 'font-size 0.3s ease',
              userSelect: 'none'
            }}
            onMouseEnter={(e) => e.currentTarget.style.fontSize = '1.75rem'} 
            onMouseLeave={(e) => e.currentTarget.style.fontSize = '1.5rem'}
          >
            {title}
          </Card.Title>
          <Card.Text
            style={{
              fontSize: '1rem',
              transition: 'font-size 0.3s ease',
              userSelect: 'none',
              marginBottom: '-6px',
            }}
            onMouseEnter={(e) => e.currentTarget.style.fontSize = '1.2rem'} font size on hover
            onMouseLeave={(e) => e.currentTarget.style.fontSize = '1rem'}
          >
            {degree}
          </Card.Text>
          <Card.Text
            style={{
              fontSize: '0.8rem',
              color: '#aaa',
              transition: 'font-size 0.3s ease',
              userSelect: 'none'
            }}
            onMouseEnter={(e) => e.currentTarget.style.fontSize = '1rem'}
            onMouseLeave={(e) => e.currentTarget.style.fontSize = '0.8rem'}
          >
            {year}
          </Card.Text>
        </Card.Body>
      </Card>
    </Col>
  );
};
