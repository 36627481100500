import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { NavBar } from "./components/NavBar";
import { Banner } from "./components/Banner";
import { Experience } from "./components/Experience";
import { Skills } from "./components/Skills";
import { Projects } from "./components/Projects";
import { Footer } from "./components/Footer";
import { Education } from './components/Education';
import { useEffect } from 'react';
import ReactGA from 'react-ga4';

const TRACKING_ID = " G-BEHLB2W193";

function App() {
  useEffect(() => {
    ReactGA.initialize(TRACKING_ID);
    ReactGA.send("pageview");
  }, []);

  return (
    <div className="App">
      <NavBar />
      <Banner />
      <Education/>
      <Experience />
      <Projects />
      <Skills />
      <Footer />
    </div>
  );
}

export default App;
