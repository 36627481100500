import React from 'react';
import { Container, Row } from 'react-bootstrap';
import { EducationCard } from './EducationCard';
import iitgImg from '../assets/img/iitg.jpg';
import colorSharp from '../assets/img/color-sharp.png';

export const Education = () => {
  const educationData = [
    {
      title: "Indian Institute of Technology Guwahati",
      degree: "Bachelor of Technology - Electronics and Communication Engineering",
      year: "2021 - 2025",
      imageUrl: iitgImg
    },
  ];
  return (
    <section className="project" id="education">
      <Container>
        <Row>
          <div className="col-12">
            <div className="education-bx wow zoomIn">
              <h2>Education</h2>
              <br></br>
              <Row>
                {educationData.map((educationItem, index) => (
                  <EducationCard key={index} {...educationItem} />
                ))}
              </Row>
            </div>
          </div>
        </Row>
      </Container>
      <img className="background-image-left" src={colorSharp} alt="Background" />
    </section>
  );
};