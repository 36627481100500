import { useState, useEffect } from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import { FaLinkedin, FaGithub, FaInstagram } from 'react-icons/fa';
import {
  BrowserRouter as Router
} from "react-router-dom";
import logo from '../assets/img/logo.svg';
import resume from "../assets/resume/resume.pdf";
import ReactGA from 'react-ga4';

export const NavBar = () => {

  const [activeLink, setActiveLink] = useState('home');
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    }

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [])

  const onUpdateActiveLink = (value) => {
    setActiveLink(value);
  }
  const downloadResume = () => {
    ReactGA.event({
      category: 'Resume',
      action: 'Download Resume',
      label: 'User clicked on resume link'
    });
    const link = document.createElement('a');
    link.href = resume;
    link.download = 'Lakshit_Sethia_Resume.pdf';
    link.click();
  };
  return (
    <Router>
      <Navbar expand="md" className={scrolled ? "scrolled" : ""}>
        <Container>
          <Navbar.Brand href="#home">
            <img src={logo} alt="Logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav">
            <span className="navbar-toggler-icon"></span>
          </Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              {/* <Nav.Link href="#home" className={activeLink === 'home' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('home')}>Home</Nav.Link> */}
              <Nav.Link href="#education" className={activeLink === 'skills' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('education')}>Education</Nav.Link>
              <Nav.Link href="#experience" className={activeLink === 'experience' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('experience')}>Experience</Nav.Link>
              <Nav.Link href="#projects" className={activeLink === 'projects' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('projects')}>Projects</Nav.Link>
              <Nav.Link href="#skills" className={activeLink === 'skills' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('skills')}>Skills</Nav.Link>
            </Nav>
            <span className="navbar-text">
              <div className="social-icon">
                <a href="https://www.linkedin.com/in/lakshit-sethia/" target="_blank" rel="noopener noreferrer"><FaLinkedin size={30} style={{ color: 'white' }}/></a>
                <a href="https://github.com/yoyobuzz" target="_blank" rel="noopener noreferrer"><FaGithub size={30} style={{ color: 'white' }}/></a>
                <a href="https://www.instagram.com/lak__it" target="_blank" rel="noopener noreferrer"><FaInstagram size={30} style={{ color: 'white' }}/></a>
              </div>
                <button className="vvd"onClick={downloadResume}><span>Download Resume</span></button>
            </span>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </Router>
  )
}
