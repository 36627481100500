import React from 'react';
import { FaPython, FaJava, FaAws, FaGoogle, FaHtml5, FaCss3Alt, FaJs, FaNodeJs, FaGit, FaGithub, FaFigma } from 'react-icons/fa';
import { TbBrandCpp } from "react-icons/tb";
import { Card, Col } from 'react-bootstrap';
import { FaC } from 'react-icons/fa6';
import { SiDart, SiScipy, SiPandas, SiNumpy, SiPytorch, SiTensorflow, SiMysql, SiMongodb, SiFlutter, SiFastapi, SiFirebase } from 'react-icons/si';
import { SiKotlin } from "react-icons/si";
import { SiPlotly } from "react-icons/si";
import { FaM } from "react-icons/fa6";

const icons = {
  Python: <FaPython />,
  Java: <FaJava />,
  AWS: <FaAws />,
  GCP: <FaGoogle />,
  HTML: <FaHtml5 />,
  CSS: <FaCss3Alt />,
  JavaScript: <FaJs />,
  NodeJs: <FaNodeJs />,
  Git: <FaGit />,
  GitHub: <FaGithub />,
  Figma: <FaFigma />,
  C: <FaC />,
  Cpp: <TbBrandCpp />,
  Dart: <SiDart />,
  SciPy: <SiScipy />,
  Pandas: <SiPandas />,
  NumPy: <SiNumpy />,
  PyTorch: <SiPytorch />,
  TensorFlow: <SiTensorflow />,
  MySQL: <SiMysql />,
  MongoDB: <SiMongodb />,
  Flutter: <SiFlutter />,
  FastAPI: <SiFastapi />,
  Firebase: <SiFirebase />,
  S3: <FaAws />,
  Kotlin: <SiKotlin />,
  Matplotlib: <SiPlotly />,
  MATLAB: <FaM />
};

const SkillCard = ({ title, skills }) => {
  return (
    <Col className="mb-4" style={{ display: 'flex' }}>
      <Card
        style={{
          backgroundColor: '#222',
          border: '2px solid #777',
          borderRadius: 35,
          overflow: 'hidden',
          textAlign: 'center',
          height: '100%',
          width: '100%',
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          padding: '20px',
          margin: '10px',
          userSelect: 'none',
          transition: 'transform 0.3s ease'
        }}
        onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.05)'}
        onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}
      >
        <Card.Body>
          <Card.Title style={{ fontSize: '1.5rem', color: '#CBC3E3' }}>{title}</Card.Title>
          <Card.Text style={{ fontSize: '1.25rem', color: '#aaa', textAlign: 'center' }}>
            <ul style={{ listStyleType: 'none', padding: 2 }}>
              {skills.map((skill, i) => (
                <li
                  key={i}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '8px',
                    transition: 'font-size 0.3s ease',
                    userSelect: 'none'
                  }}
                  onMouseEnter={(e) => e.currentTarget.style.fontSize = '1.5rem'}
                  onMouseLeave={(e) => e.currentTarget.style.fontSize = '1.25rem'}
                >
                  {icons[skill] && <span style={{ marginRight: '12px', color: '#AA336A' }}>{icons[skill]}</span>}
                  {skill}
                </li>
              ))}
            </ul>
          </Card.Text>
        </Card.Body>
      </Card>
    </Col>
  );
};

export default SkillCard;
