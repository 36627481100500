import { Container, Row, Col } from "react-bootstrap";
// import { Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import coursehub from "../assets/img/coursehub.png";
import photon from "../assets/img/photon.png";
import fpga from "../assets/img/fpga.png";
import serverlesscpp from "../assets/img/serverlesscpp.png";
import videocaptioning from "../assets/img/video-captioning.png";
import colorSharp2 from "../assets/img/color-sharp.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Projects = () => {
// const projectsDev = [
//   {
//     title: "Coursehub",
//     description: "Developed a comprehensive academic resource platform with 2,000+ app downloads and 40,000+ site visits.",
//     imgUrl: projImg1,
//   },
//   {
//     title: "Photon",
//     description: "Created an AI-powered Album Sorter with facial recognition and secure OAuth2 authentication.",
//     imgUrl: projImg2,
//   },
//   {
//     title: "P2P Serverless Chatroom using C++",
//     description: "Developed a peer-to-peer chatroom application using C++ without a central server.",
//     imgUrl: projImg3,
//   },
//   {
//     title: "Facial Recognition on an FPGA",
//     description: "Implemented a facial recognition system using ResNet-18 on an FPGA board with 96% accuracy.",
//     imgUrl: projImg1,
//   },
// ];

const projectsML = [
  {
    title: "Coursehub",
    description: "Developed a comprehensive academic resource platform with 2,000+ app downloads and 40,000+ site visits.",
    imgUrl: coursehub,
    projectLink: "https://play.google.com/store/apps/details?id=com.codingclub.coursehub&hl=en&pli=1",
    sourceCodeLink: "https://www.coursehubiitg.in",
  },
  {
    title: "Photon",
    description: "Created an AI-powered Album Sorter with facial recognition and secure OAuth2 authentication.",
    imgUrl: photon,
    projectLink: "https://photon.lakshitsethia.in/",
    sourceCodeLink: "https://github.com/yoyobuzz/album-sorter-backend",
  },
  {
    title: "P2P Serverless Chatroom using C++",
    description: "Developed a peer-to-peer chatroom application using C++ without a central server.",
    imgUrl: serverlesscpp,
    projectLink: "https://github.com/yoyobuzz/p2p-serverless-chat-cpp",
    sourceCodeLink: "https://github.com/yoyobuzz/p2p-serverless-chat-cpp",
  },
  {
    title: "Facial Recognition on an FPGA",
    description: "Implemented a facial recognition system using ResNet-18 on an FPGA board with 96% accuracy.",
    imgUrl: fpga,
    projectLink: "https://drive.google.com/file/d/1aUbyB_ntIX3FehPZ3ZdB-l5dUzCWDiBV/view",
    sourceCodeLink: "https://github.com/yoyobuzz/fpga-face-recog",
  },
  {
    title: "Video Captioning",
    description: "Devised a video summarizer model for generating captions on small video clips using Computer Vision and NLP.",
    imgUrl: videocaptioning,
    projectLink: "https://github.com/yoyobuzz/video-captioning",
    sourceCodeLink: "https://github.com/yoyobuzz/video-captioning",
  },
];

  return (
    <section className="project" id="projects">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn": ""}>
                <h2>Projects</h2>
                <br></br>
                <Row>
                  {
                    projectsML.map((project, index) => (
                      <ProjectCard
                        key={index}
                        {...project}
                      />
                    ))
                  }
                </Row>
                {/* <Tab.Container id="projects-tabs" defaultActiveKey="first">
                  <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab" fill="true">
                    <Nav.Item>
                      <Nav.Link eventKey="first">Dev</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">ML/AI</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                    <Tab.Pane eventKey="first">
                      <Row>
                        {
                          projectsDev.map((project, index) => (
                            <ProjectCard
                              key={index}
                              {...project}
                            />
                          ))
                        }
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                      <Row>
                        {
                          projectsML.map((project, index) => (
                            <ProjectCard
                              key={index}
                              {...project}
                            />
                          ))
                        }
                      </Row>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container> */}
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2} alt="Background" />
    </section>
  );
}
