import { Container, Row, Col } from "react-bootstrap";
import logo from "../assets/img/logo.svg";
import { FaLinkedin, FaGithub, FaInstagram } from 'react-icons/fa';

export const Footer = () => {
  return (
    <footer className="footer" style={{ padding: '10vh 8vh', height: 'auto' }}>
      <Container>
        <Row className="align-items-center" style={{ borderRadius: '5px' }}>
          <Col xs={12} sm={6} className="text-center text-sm-start">
            <img src={logo} alt="Logo" style={{ maxWidth: '100%', height: 'auto' }} />
          </Col>
          <Col xs={12} sm={6} className="text-center text-sm-end mt-3 mt-sm-0">
            <div className="social-icon">
              <a href="https://www.linkedin.com/in/lakshit-sethia/" target="_blank" rel="noopener noreferrer">
                <FaLinkedin size={30} style={{ color: 'white', margin: '0 10px' }} />
              </a>
              <a href="https://github.com/yoyobuzz" target="_blank" rel="noopener noreferrer">
                <FaGithub size={30} style={{ color: 'white', margin: '0 10px' }} />
              </a>
              <a href="https://www.instagram.com/lak__it" target="_blank" rel="noopener noreferrer">
                <FaInstagram size={30} style={{ color: 'white', margin: '0 10px' }} />
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}
