import { useState, useEffect, useCallback } from "react";
import { Container, Row, Col } from "react-bootstrap";
import headerImg from "../assets/img/me.png";
import resume from "../assets/resume/resume.pdf";
import { ArrowRightCircle } from 'react-bootstrap-icons';
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Banner = () => {
  const [loopNum, setLoopNum] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [text, setText] = useState('');
  const [isPaused, setIsPaused] = useState(false);
  const period = 60;
  const pauseDuration = 750;

  const tick = useCallback(() => {
    const toRotate = ["a Problem Solver", "a Competitive Programmer", "an App Developer", "an ML Engineer"];
    const i = loopNum % toRotate.length;
    const fullText = toRotate[i];
    const updatedText = isDeleting ? fullText.substring(0, text.length - 1) : fullText.substring(0, text.length + 1);

    setText(updatedText);

    if (!isDeleting && updatedText === fullText) {
      setIsPaused(true);
      setTimeout(() => {
        setIsPaused(false);
        setIsDeleting(true);
      }, pauseDuration);
    } else if (isDeleting && updatedText === '') {
      setIsDeleting(false);
      setLoopNum(loopNum + 1);
    }
  }, [isDeleting, loopNum, text.length]);

  useEffect(() => {
    if (!isPaused) {
      const ticker = setInterval(() => {
        tick();
      }, period);

      return () => clearInterval(ticker);
    }
  }, [tick, isPaused]);

  const downloadResume = () => {
    const link = document.createElement('a');
    link.href = resume;
    link.download = 'Lakshit_Sethia_Resume.pdf';
    link.click();
  };

  return (
    <section className="banner" id="home">
      <Container>
        <Row className="align-items-center">
          <Col xs={12} md={6} xl={7}>
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                  <h1>
                    {`Hi! I'm `}
                    <span style={{
                      background: 'linear-gradient(0deg, red 0%, orange 40%, yellow 100%)',
                      WebkitBackgroundClip: 'text',
                      WebkitTextFillColor: 'transparent'
                    }}>
                      Lakshit Sethia
                    </span>
                    {`, `}
                    <br></br>
                    <span className="wrap">{text}</span>
                  </h1>
                  <p>Dedicated and results-driven student with a passion for technology and its use for problem-solving. I thrive on challenges and am known for my ability to deliver impactful solutions that positively impact organizations.</p>
                  <button onClick={downloadResume}>Download Resume <ArrowRightCircle size={25} /></button>
                </div>}
            </TrackVisibility>
          </Col>
          <Col xs={12} md={6} xl={5}>
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__zoomIn" : ""}>
                  <img src={headerImg} alt="Header Img" />
                </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
