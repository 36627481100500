import React, { useState } from 'react';
import { Card, Col } from 'react-bootstrap';
import { FaCode, FaExternalLinkAlt } from 'react-icons/fa';
import ReactGA from 'react-ga4';

export const ProjectCard = ({ title, description, imgUrl, projectLink, sourceCodeLink }) => {
  const [hover, setHover] = useState(false);

  const trackProjectClick = () => {
    ReactGA.event({
      category: 'Project',
      action: 'Click Project Link',
      label: title,
    });
  };

  const trackSourceCodeClick = () => {
    ReactGA.event({
      category: 'Project',
      action: 'Click Source Code Link',
      label: title,
    });
  };

  return (
    <Col xs={12} sm={6} md={4} className="mb-4">
      <Card
        style={{
          position: 'relative',
          backgroundColor: '#151515',
          color: 'white',
          border: 'none',
          height: '100%',
          borderRadius: 25,
          overflow: 'hidden',
          transition: 'transform 0.3s ease-in-out',
          transform: hover ? 'scale(1.05)' : 'scale(1)',
        }}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <Card.Img
          variant="top"
          src={imgUrl}
          style={{
            borderTopLeftRadius: 25,
            borderTopRightRadius: 25,
            border: '8px solid #151515',
          }}
        />
        <Card.Body>
          <Card.Title style={{ fontSize: '1.25rem', textAlign: 'center' }}>{title}</Card.Title>
          <Card.Text style={{ fontSize: '1rem', textAlign: 'center', width: '85%'}}>
            {description}
          </Card.Text>
        </Card.Body>
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            opacity: hover ? 1 : 0,
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
            transition: 'opacity 0.3s ease-in-out',
          }}
        >
          <div style={{ display: 'flex', gap: '20px' }}>
            <a
              href={projectLink}
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: 'white', fontSize: '1.5rem' }}
              onClick={trackProjectClick}
            >
              <FaExternalLinkAlt />
            </a>
            <a
              href={sourceCodeLink}
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: 'white', fontSize: '1.5rem' }}
              onClick={trackSourceCodeClick}
            >
              <FaCode />
            </a>
          </div>
        </div>
      </Card>
    </Col>
  );
};