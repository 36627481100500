import React from 'react';
import { Container, Row, Carousel } from 'react-bootstrap';
import { ExperienceCard } from './ExperienceCard';
import barclaysImg from '../assets/img/barclays.png';
import medsoluImg from '../assets/img/medsolu.png';
export const Experience = () => {
  const experiences = [
    
    {
      company: "Barclays",
      role: "Software Engineering Intern",
      duration: "May 2024 - July 2024",
      imageUrl: barclaysImg
    },
    {
      company: "MedSolu",
      role: "Machine Learning & App Dev Intern",
      duration: "June 2023 - April 2024",
      imageUrl: medsoluImg
    },
  ];

  return (
    <section style={{ width: '100%', padding: '80px 0' }} className="project" id="experience">
      <Container>
        <Row>
          <div style={{ width: '100%' }}>
            <div style={{ textAlign: 'center' }}>
              <h2>Work Experience</h2>
              <br />
              <Carousel 
                controls={false} 
                indicators={true} 
                interval={1500}
                style={{ maxWidth: '100%', margin: '0 auto' }}
              >
                {experiences.map((experience, index) => (
                  <Carousel.Item key={index}>
                      <ExperienceCard {...experience} />
                  </Carousel.Item>
                ))}
              </Carousel>
            </div>
          </div>
        </Row>
      </Container>
    </section>
  );
};
